<template>
    <div class="app flex-row align-items-center background-padrao">
        <div class="container">
            <b-row class="justify-content-center row-recuperar-senha">
                <img src="./../../assets/img/logo-union-login.png" alt="Logo Union" class="logo-union">
                <b-col :md="this.loggedIn ? '6' : '4'">
                    <b-card-group class="cardRecuperarSenha p-4" v-if="!codigoEnviado">
                        <b-card-body>
                            <b-form>
                                <b-form-text class="texto-recuperar-senha mb-5">
                                    Insira seu e-mail, para receber as instruções para criar uma nova senha.
                                </b-form-text>
                                <b-input-group class="mb-5">
                                    <b-input-group-prepend>
                                        <b-input-group-text>
                                            <i class="icon-user"></i>
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-input
                                    type="text"
                                    class="input-email"
                                    placeholder="Email"
                                    v-on:keyup.enter="enviarCodigo()"
                                    v-model="email"
                                    />
                                </b-input-group>
                                <b-row class="row-btn">
                                    <b-col cols="8">
                                        <b-button class="px-4 btn-enviar" v-on:click="enviarCodigo()">Enviar</b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card-body>
                    </b-card-group>  

                    <b-card-group class="cardRecuperarSenha p-4" v-else-if="codigoEnviado && !codigoConfirmado">
                        <b-card-header header="Verificação de Segurança" class="p-2"/>
                        <b-card-body>
                            <b-form>
                                <b-form-text class="texto-recuperar-senha mb-3"> 
                                    Um código de verificação foi enviado para o seu e-mail.
                                </b-form-text>
                                <b-row class="row-btn">
                                    <b-col cols="10">
                                        <b-input-group class="mb-1">
                                            <b-input 
                                            class="input-codigo"
                                            placeholder="Ex.: 123456"
                                            v-on:keyup.enter="validarCodigo()"
                                            v-model="codigo"/>
                                        </b-input-group>
                                    </b-col>
                                </b-row>
                                <b-row class="row-btn mb-2">
                                    <b-col cols="8">
                                        <b-button variant="link" id="btn-reenviarCodigo" @click="reenviarCodigo()" :disabled="reenvioCount != 0"> 
                                            Reenviar código {{ reenvioCount != 0 ? formatSeconds(reenvioCount) : "" }}
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="row-btn mb-2">
                                    <b-col cols="8">
                                        <b-button class="btn-enviar" v-on:click="validarCodigo()">
                                            Validar
                                        </b-button>
                                    </b-col>
                                </b-row>
                                <b-row class="row-btn">
                                    <router-link to="/Login"><b-button variant="link" id="btn-cancelar">
                                        Cancelar
                                    </b-button></router-link>
                                </b-row>
                                <b-row>

                                </b-row>
                            </b-form>
                        </b-card-body>
                        <b-card-footer>
                            <b-row class="row-footer">
                                Não Recebeu o código de verificação?&nbsp;
                                <img src="./../../assets/img/iconewhats.png" alt="Ícone do telefone whatsapp do suporte">
                                <b-button variant="link" class="btn-suporte" :href="retornarLinkWhatsapp()">
                                    Fale Conosco
                                </b-button>
                            </b-row>
                        </b-card-footer>
                    </b-card-group>

                    <b-card-group class="cardRecuperarSenha p-4" v-else-if="codigoEnviado && codigoConfirmado && !loggedIn">
                        <b-card-header header="Escolha uma senha!"/>
                        <b-card-body>
                            <b-form>
                                <b-input-group class="mb-4">
                                    <b-input-group-prepend>
                                        <b-input-group-text>
                                            <i class="icon-lock"></i>
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-input 
                                    placeholder="Insira uma senha"
                                    class="input-senha"
                                    :type="novaSenhaTypeToggle"
                                    v-model="senhaNova"
                                    />
                                    <b-input-group-append>
                                        <b-input-group-text class="i-olho">
                                            <i class="icon-eye i-olho" @click="senhaNovaVisivel = !senhaNovaVisivel"></i>
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>

                                <b-input-group class="mb-4">
                                    <b-input-group-prepend>
                                        <b-input-group-text>
                                            <i class="icon-lock"></i>
                                        </b-input-group-text>
                                    </b-input-group-prepend>
                                    <b-input 
                                    placeholder="Confirmar senha"
                                    class="input-senha"
                                    :type="novaSenhaConfirmacaoTypeToggle"
                                    v-model="senhaNovaConfirmacao"/>
                                    <b-input-group-append>
                                        <b-input-group-text class="i-olho btn-outline-light" @click="senhaNovaConfirmacaoVisivel = !senhaNovaConfirmacaoVisivel">
                                            <i class="icon-eye i-olho"></i>
                                        </b-input-group-text>
                                    </b-input-group-append>
                                </b-input-group>

                                <b-row class="row-btn row-txt-requisitos mb-2">
                                    Requisitos para uma senha segura:
                                </b-row>
                                <b-row class="row-btn mb-3">
                                    <b-list-group class="list-group-requisitos">
                                        <b-list-group-item>
                                            <i class="icon-close" style="color:red;" v-if="!validacoesSenha.nroCaracteres"></i>
                                            <i class="icon-check" style="color:green;" v-else></i>
                                             Use pelo menos 8 caracteres 
                                        </b-list-group-item>
                                        <b-list-group-item> 
                                            <i class="icon-close" style="color:red;" v-if="!validacoesSenha.charMaiusculo"></i>
                                            <i class="icon-check" style="color:green;" v-else></i>
                                            Use caracteres maiúsculos 
                                        </b-list-group-item>
                                        <b-list-group-item> 
                                            <i class="icon-close" style="color:red;" v-if="!validacoesSenha.charMinusculo"></i>
                                            <i class="icon-check" style="color:green;" v-else></i>
                                            Use caracteres minúsculos 
                                        </b-list-group-item>
                                        <b-list-group-item>
                                            <i class="icon-close" style="color:red;" v-if="!validacoesSenha.contemNumeros"></i>
                                            <i class="icon-check" style="color:green;" v-else></i>
                                            Insira números 
                                        </b-list-group-item>
                                        <b-list-group-item> 
                                            <i class="icon-close" style="color:red;" v-if="!validacoesSenha.charEspecial"></i>
                                            <i class="icon-check" style="color:green;" v-else></i>
                                            Insira carácteres especiais 
                                        </b-list-group-item>
                                    </b-list-group>
                                </b-row>
                                <b-row class="row-btn">
                                    <b-col cols="7">
                                        <b-button class="btn-enviar" @click="alterarSenha()"> 
                                            Confirmar
                                        </b-button>
                                    </b-col>
                                </b-row>
                            </b-form>
                        </b-card-body>
                    </b-card-group>


                    <resale-selector 
                        :empresas="resales" 
                        :dataExpiracao="expirationDate" 
                        :usuario="user" 
                        :nroSuporte="telefoneSuporte" v-else></resale-selector>
                </b-col>                               
            </b-row>
        </div>
    </div>


</template>

<style scoped>
    .background-padrao {
    background-image: url("./../../assets/img/fundo_login.png");
    }
    .row-recuperar-senha {
        flex-direction: column;
        align-items: center;
    }
    .cardRecuperarSenha{
        background-color: white;
        margin-top: 3rem;
        padding: 1em!important;
        border-radius: 1em;
    }
     .input-group-text {
        border-bottom-left-radius: 50px;
        border-top-left-radius: 50px;
        border-color: orangered;
    }

    .input-group input {
        border-color: orangered;
    }

    .input-email{
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
    }

    .i-olho {
        background-color: white;
        color: grey;
        border-bottom-right-radius: 50px;
        border-top-right-radius: 50px;
        border-color: orangered;
    }

    .card-header{
        background: white;
        border: none;
        margin: auto;
        font-size: 1.2em;
        font-weight: bold;

    }
    .row-footer{
        align-items: center;
    }
    .card-footer{
        background: white;
        border: none;
        font-size: 0.9em;
    }
    .card-footer button{
        font-size: 1em;
        color: black;
        text-decoration: underline;
        float: right;
        padding: 0;
    }
    .card-footer img{
        width: 1.5em;
    }
    .texto-recuperar-senha {
        font-size: 1.1em;
        color: black;
        font-weight: bold;
        text-align: center;
    }
    .row-btn{
        justify-content: center;
        display: flex;
    }
    .btn-enviar{
        font-size: 1.2em;
        border: none;
        background-color: darkorange;
        color: white;
        border-radius: 2em;
        width: 100%;
        padding: 0.2em 1em;
    }

    .btn-enviar:hover{
        background-color: #f08a06;
    }

    #btn-reenviarCodigo{
        font-size: 0.9em;
        color: black;
        width: 100%;
    }
    #btn-cancelar {
        font-size: 1.2em;
        color: black;
        text-decoration: underline;
    }

    .btn-suporte {
        color: black;
        padding: 0;
        font-size: 1em;
    }

    .input-codigo{
        border-radius: 50px;
        border-color: orangered;
        text-align: center;
    }

    .input-senha{
        border-right: none;
    }

    .row-txt-requisitos{
        font-weight: bold;
    }
    
    .list-group-item{
        border: none;
        padding: 0.3em;
    }
</style>

<script>
import ResetSenha from "./../../services/resetSenha";
import Autenticar from "./../../services/autenticar";
import Carrinho from "./../../services/carrinho";
import ResaleSelector from "../../components/ResaleSelector.vue";

export default 
  {
    components: { ResaleSelector },
    props: ['empresas', 'usuario', 'dataExpiracao', 'nroSuporte'],
    name: "recuperarSenha",
    data() {
        return {
            email: "",
            codigo: "",
            reenvioCount: 0,
            telefoneSuporte: "",
            codigoEnviado: false,
            codigoConfirmado: false,
            senhaNova: "",
            senhaNovaConfirmacao: "",
            senhaNovaVisivel: false,
            senhaNovaConfirmacaoVisivel: false,
            validacoesSenha: {
                nroCaracteres: false,
                charMaiusculo: false,
                charMinusculo: false,
                contemNumeros: false,
                charEspecial: false
            },
            loggedIn: false,
            resales: [],
            user: "",
            expirationDate: ""
        }
    },
    mounted() {
        this.buscarWhatsappSuporte();
    },
    methods: {
        enviarCodigo() {
            if(this.email == ""){
                this.$helper.showMsg("Preencha o email.", "warning");
                return;
            }
            if(!this.validaFormatoEmail()){
                this.$helper.showMsg("Insira um formato de email válido.", "warning");
                return;
            }
            ResetSenha.enviarCodigo(this.email)
            .then(response => {
                if (response.status == 204 ){
                    this.codigoEnviado = true;
                }
            })
            .catch(e => {
                this.email = "";
                this.codigoEnviado = false;
                this.$helper.showErrorResponse(e);
            });
        },
        reenviarCodigo(){
            this.enviarCodigo();
            this.reenvioCount = 120;
        },
        validarCodigo(){
            if(this.codigo == ""){
                this.$helper.showMsg("Preencha o código.", "warning");
                return;
            }
            const data = {
                email: this.email,
                codigo: this.codigo
            }
            ResetSenha.validarCodigo(data)
            .then(response => {
                const codigoValido = response.data.data.codigoValido;
                this.codigoConfirmado = codigoValido;
                if(!this.codigoConfirmado){
                    this.codigo = "";
                    this.$helper.showMsg("Código não encontrado")
                }
            })
            .catch(e => {
                this.codigo = "";
                this.codigoConfirmado = false;
                this.$helper.showErrorResponse(e);
            })
        },
        alterarSenha(){
            if(this.senhaNova != this.senhaNovaConfirmacao){
                this.$helper.showMsg("Ambas as senhas precisam ser iguais!", "warning");
                return;
            }
            for(const value of Object.values(this.validacoesSenha)){
                if(!value){
                    this.$helper.showMsg("A senha precisa atender todos os requisitos listados!", "warning");
                    return;
                }
            }
            ResetSenha.trocarSenha(this.email, this.senhaNova, this.codigo)
            .then(response => {
                if(response.status == 204){
                    this.$helper.showMsg("Senha alterada com sucesso!", "success");
                    this.login();
                }else if(response.status == 200){
                    this.$helper.showMsg(response.data.msg, "warning")
                }
            }).catch(e => {
                this.$helper.showErrorResponse(e);
            })
        },
        useResale(resale) {
            this.$store.commit("login/CURRENT_RESALE", resale);
            this.$store.commit("login/CURRENT_USER", this.user);
            this.$store.commit("login/X_API_KEY", resale.key);
            this.$store.commit("login/EXPIRATION_DATE", this.expirationDate);
            this.loadCurrentCart();
            this.$router.push("/");
        },
        loadCurrentCart() {
            this.$store.commit("carrinho/RESET");
            Carrinho.getCarrinho()
            .then(response => {
            this.$store.commit("carrinho/CURRENT_CART", response.data.data);
            })
            .catch(() => {});
        },
        login() {
            this.resales = [];
            Autenticar.login(this.email, this.senhaNova)
                .then(response => {
                this.user = null;
                this.expirationDate = null;
                this.loggedIn = false;
                this.resales = response.data.keys;
                this.user = response.data.user;
                this.expirationDate = response.data.expiration_date;
                if(this.resales.length == 1){
                    this.useResale(this.resales[0]);
                } else {
                    this.loggedIn = true;
                }
                })
                .catch(e => {
                this.loggedIn = false;
                this.resales = [];
                this.$helper.showErrorResponse(e);
                this.$router.push({ name: 'Login' });
                });
      
        },
        buscarWhatsappSuporte(){
            ResetSenha.buscaTelefoneSuporte()
            .then(response => {
                this.telefoneSuporte = response.data.data.whatsapp;
            });
        },
        validaFormatoEmail(){
            const regexEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if(!regexEmail.test(this.email)){
                return false;
            }
            return true;
        },
        formatSeconds(seconds) {
            const minutes = Math.floor(seconds / 60);
            const remainingSeconds = seconds % 60;
            return `${this.padZero(minutes)}:${this.padZero(remainingSeconds)}`;
        },
        padZero(number) {
            return number < 10 ? `0${number}` : number;
        }
    },
    computed: {
        retornarLinkWhatsapp(){
            return () => {
                return this.$store.state.configPadrao.linkWhatsapp + this.telefoneSuporte;
            }
        },
        novaSenhaTypeToggle(){
            return this.senhaNovaVisivel ? 'text' : 'password';
        },
        novaSenhaConfirmacaoTypeToggle(){
            return this.senhaNovaConfirmacaoVisivel ? 'text' : 'password';
        }
    },
    watch: {
        codigoEnviado(codigoEnviado){
            if(codigoEnviado){
                this.reenvioCount = 120
            }
        },
        reenvioCount(reenvioCount){
            if(reenvioCount > 0){
                setTimeout(() => {
                    this.reenvioCount--;
                }, 1000);
            }
        },
        senhaNova(senhaNova) {
            this.validacoesSenha.nroCaracteres = senhaNova.length > 8;
            this.validacoesSenha.charMaiusculo = /[A-Z]/.test(senhaNova);
            this.validacoesSenha.charMinusculo = /[a-z]/.test(senhaNova);
            this.validacoesSenha.charEspecial = /[.,!@#$%^&*()_+\-=]/.test(senhaNova);
            this.validacoesSenha.contemNumeros = /\d/.test(senhaNova) ;
        }
    }
}
</script>
