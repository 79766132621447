import {http} from './config'

export default {
    enviarCodigo: (email) => {
        return http.post('reset_senha/enviar', { 
            "email": email 
        });
    },
    validarCodigo: (data) => {
        const query = new URLSearchParams(data).toString();
        return http.get(`reset_senha/validar_codigo?${query}`);
    },
    buscaTelefoneSuporte: () => {
        return http.get('reset_senha/atendimento')
    },
    trocarSenha: (email, newPassword, codigo) => {
        return http.put('reset_senha/trocar_senha',
            {
                "email": email,
                "newPassword": newPassword,
                "codigo": codigo
            }
        )
    }
}